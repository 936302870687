import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground, Props } from 'docz';
import { Pagination } from '@class101/ui';
import { PlayGroundContainer, ThemeProvider } from '../docsComponents';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "pagination"
    }}>{`Pagination`}</h1>
    <p>{`Pagination is a component used to display a long list divided by one page.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Pagination} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<PlayGroundContainer>\n  <Pagination\n    currentPageIndex={0}\n    totalPageIndex={1}\n    onChange={number => alert(number)}\n  />\n</PlayGroundContainer>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Pagination,
      PlayGroundContainer,
      ThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PlayGroundContainer mdxType="PlayGroundContainer">
    <Pagination currentPageIndex={0} totalPageIndex={1} onChange={number => alert(number)} mdxType="Pagination" />
  </PlayGroundContainer>
    </Playground>
    <h2 {...{
      "id": "with-many-datas"
    }}>{`With Many Datas`}</h2>
    <Playground __position={2} __code={'() => {\n  const [currentPageIndex, setCurrentPageIndex] = useState(0)\n  return (\n    <Pagination\n      currentPageIndex={currentPageIndex}\n      totalPageIndex={8}\n      onChange={number => setCurrentPageIndex(number)}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Pagination,
      PlayGroundContainer,
      ThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [currentPageIndex, setCurrentPageIndex] = useState(0);
        return <Pagination currentPageIndex={currentPageIndex} totalPageIndex={8} onChange={number => setCurrentPageIndex(number)} mdxType="Pagination" />;
      }}
    </Playground>
    <h2 {...{
      "id": "assigning-how-many-numbered-index-buttons-to-be-shown"
    }}>{`Assigning how many numbered index buttons to be shown`}</h2>
    <p>{`You can assign how many numbered index buttons to be showing by passing down
`}<inlineCode parentName="p">{`number`}</inlineCode>{` to the `}<inlineCode parentName="p">{`pageCountPerView`}</inlineCode>{` prop. It should be an odd
number since the focused button needs to be centered`}</p>
    <Playground __position={3} __code={'() => {\n  const [currentPageIndex, setCurrentPageIndex] = useState(0)\n  return (\n    <Pagination\n      currentPageIndex={currentPageIndex}\n      totalPageIndex={15}\n      pageCountPerView={9}\n      onChange={number => setCurrentPageIndex(number)}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      Props,
      Pagination,
      PlayGroundContainer,
      ThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [currentPageIndex, setCurrentPageIndex] = useState(0);
        return <Pagination currentPageIndex={currentPageIndex} totalPageIndex={15} pageCountPerView={9} onChange={number => setCurrentPageIndex(number)} mdxType="Pagination" />;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      